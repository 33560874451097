<template>
  <div style="background:white">
    <img :src="require('@/assets/szyg.jpg')" style="width: 100%">
    <menu-card :data="this.data"></menu-card>
  </div>
</template>

<script>
import MenuCard from '@/components/Card/MenuCard'
import {getDigitalRoot} from '@/api/digital';

export default {
  name: "DigitalEmployees",
  components: {MenuCard},
  data() {
    return {
      see:[],
      data: [
        {
          title: '数字员工',
          columnNum: 2,
          imgFlag: true,
          child: [
            {
              id: 'DigitalApp',
              icon: 'apps-o',
              text: '员工应用',
              index: 'os1',
              root: false
            },
            {
              id: 'DigitalGrow',
              icon: 'user-circle-o',
              text: '员工成长',
              index: '2',
              root: false
            },
            {
              id: 'DigitalDangAn',
              icon: 'notes-o',
              text: '员工档案',
              index: 'ti1',
              root: false
            },
            {
              id: 'DigitalAsset',
              icon: 'balance-o',
              text: '员工资产',
              index: '4',
              root: false
            },
          ]
        }
      ],
    }
  },
  mounted() {
    getDigitalRoot({userNo: this.$store.getters.userId}).then(res => {
      this.data[0].child[0].root = res[0].root1
      this.data[0].child[1].root = res[0].root2
      this.data[0].child[2].root = res[0].root3
      this.data[0].child[3].root = res[0].root4
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {}
}
</script>

<style scoped>
.role-row {
  background: #E6E6E6;
  padding: 20px 15px;
  margin: 20px 10px;
  border-radius: 12px;
}

.section {
  padding: 5px;
  overflow: hidden;
}

.section .card {
  margin-bottom: 6px;
  padding: 12px 0;
  background-color: #fff;
  border-radius: 12px;
}

.section .card-border {
  box-shadow: #b6c9f1 0 0 5px 2px;
}

.section .card h3 {
  padding-left: 20px;
  margin-top: 4px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  position: relative;
}

.section .card h3:before {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 5px;
  height: 16px;
  background-color: #ee0a24;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.not-img-title {
  margin-bottom: 6px;
  margin-top: 6px;
  font-weight: 500;
  font-size: 20px;
}

.not-img-text {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
}
</style>